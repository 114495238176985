import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
    return request.post(constant.serverUrl + "/bus/schoolBusHandover/pageList", formData);
}

function getSelectWeek(date){
    return request.post(constant.serverUrl + "/bus/schoolBusHandover/getSelectWeek/"+date);
}

function headerData(formData){
    return request.post(constant.serverUrl + "/bus/schoolBusHandover/headerData/",formData);
}

function getDefaultSchedule(routeId){
    return request.post(constant.serverUrl + "/bus/schoolBusHandover/getDefaultSchedule/"+routeId);
}

function exportXls(formData){
    return request.post(constant.serverUrl + "/bus/schoolBusHandover/exportXls/",formData);
}

export default {
    pageList,getSelectWeek,headerData,exportXls,getDefaultSchedule
}