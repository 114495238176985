<template>
    <div class="schoolBusHandover-list">
        <el-breadcrumb separator=">">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>
            <a href="#">校车管理</a>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
            <a href="#">校车交接</a>
        </el-breadcrumb-item>
        </el-breadcrumb>
        <el-divider></el-divider>
        <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline" :rules="ruleValidate">
            <el-form-item label="所属学校" prop="schoolId" label-width="80px">
                <el-select v-model="queryModel.schoolId" filterable placeholder="请选择" size="mini">
                    <el-option v-for="item in schoolList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="选择线路" prop="routeId" label-width="80px">
                <el-select v-model="queryModel.routeId" filterable placeholder="请选择" size="mini">
                    <el-option v-for="item in routeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="选择日期" prop="beginDate" label-width="80px" >
                <template>
                    <div class="block">
                        <el-date-picker size="mini"
                        v-model="queryModel.beginDate"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </div>
                </template>
            </el-form-item>
            <el-form-item>
                <el-button
                type="primary"
                size="mini"
                icon="ios-search"
                @click="handleQuery('queryForm')"
                :loading="loading"
                >查询</el-button>&nbsp;
                <el-button
                type="info"
                size="mini"
                style="margin-left: 8px"
                @click="handleReset('queryForm')"
                >重置</el-button>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <template>
            <el-tabs v-model="activeName"  type="border-card" @tab-click="handleClick">
                <el-tab-pane
                    v-for="item in scheduleList"
                    :key="item.id"
                    :label="item.name"
                    :name="item.id"
                    v-loading="loading"
                >
                    <el-row class="button-group">
                    <el-button
                        type="primary"
                        size="small"
                        plain
                        icon="el-icon-download"
                        :loading="downloadLoading"
                        @click="downloadXls"
                    >导出数据</el-button>
                    </el-row>
                    <div class="headerDateDiv">
                        <span>学校：{{schoolName}}</span>
                        <span class="headerDateSpan">车牌号：{{licensePlateNumber}}</span>
                        <span class="headerDateSpan">驾驶员：{{driverName}}</span>
                        <span class="headerDateSpan">照看员：{{lifeguardName}}</span>
                        <span class="headerDateSpan">线路：{{routeName}}</span>
                        <span class="headerDateSpan">班次：{{scheduleName}}</span>
                    </div>
                    <el-table
                        ref="formTable"
                        :data="tableData"
                        v-loading="loading"
                        element-loading-text="拼命加载中"
                        stripe
                    >
                    <el-table-column prop="name" label="学生姓名" ></el-table-column>
                    <template v-for="col in tableConfig">
                        <el-table-column :label="col.label" :prop="col.prop" :key="col.name" >
                            <template v-for="item in col.children">
                                <el-table-column :label="item.label" :prop="item.prop" :key="item.name" v-if="item.prop=='mondayAM'">
                                    <template slot-scope="{row}">
                                        <div v-if="row.mondayAMstatus=='1'||row.mondayAMstatus=='2'" style="color:green">
                                            √<br>
                                            {{row.mondayAM}}<br>
                                            <el-image v-if="row.mondayAMsignUrl" style="width: 100px; height: 20px"
                                            :src="row.mondayAMsignUrl"></el-image>
                                        </div>
                                        <div v-else-if="row.mondayAMstatus=='4'" style="color:blue">
                                            △<br>
                                            {{row.mondayAM}}
                                        </div>
                                        <div v-else-if="row.mondayAMstatus=='3'" style="color:red">
                                            ○<br>
                                            {{row.mondayAM}}
                                        </div>
                                        <div v-else-if="row.mondayAMstatus=='0'" style="color:red" >
                                            ×<br>
                                            {{row.mondayAM}}
                                        </div>
                                        <div v-else>
                                            <span v-if="row.name=='接送人数'">
                                                {{row.mondayAM}}
                                            </span>
                                            <span v-else>
                                                <span v-if="row.mondayAM">
                                                    <el-image style="width: 100px; height: 40px"
                                                    :src="row.mondayAM"></el-image>
                                                </span>
                                            </span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column :label="item.label" :prop="item.prop" :key="item.name" v-if="item.prop=='mondayPM'">
                                    <template slot-scope="{row}">
                                        <div v-if="row.mondayPMstatus=='1'||row.mondayPMstatus=='2'" style="color:green">
                                            √<br>
                                            {{row.mondayPM}}<br>
                                            <el-image v-if="row.mondayPMsignUrl" style="width: 100px; height: 20px"
                                            :src="row.mondayPMsignUrl"></el-image>
                                        </div>
                                        <div v-else-if="row.mondayPMstatus=='4'" style="color:blue">
                                            △<br>
                                            {{row.mondayPM}}
                                        </div>
                                        <div v-else-if="row.mondayPMstatus=='3'" style="color:red">
                                            ○<br>
                                            {{row.mondayPM}}
                                        </div>
                                        <div v-else-if="row.mondayPMstatus=='0'" style="color:red" >
                                            ×<br>
                                            {{row.mondayPM}}
                                        </div>
                                        <div v-else>
                                            <span v-if="row.name=='接送人数'">
                                                {{row.mondayPM}}
                                            </span>
                                            <span v-else>
                                                <span v-if="row.mondayPM">
                                                    <el-image style="width: 100px; height: 40px"
                                                    :src="row.mondayPM"></el-image>
                                                </span>
                                            </span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column :label="item.label" :prop="item.prop" :key="item.name" v-if="item.prop=='tuesdayAM'">
                                    <template slot-scope="{row}">
                                    <div v-if="row.tuesdayAMstatus=='1'||row.tuesdayAMstatus=='2'" style="color:green">
                                        √<br>
                                        {{row.tuesdayAM}}<br>
                                        <el-image v-if="row.tuesdayAMsignUrl" style="width: 100px; height: 20px"
                                        :src="row.tuesdayAMsignUrl"></el-image>
                                    </div>
                                    <div v-else-if="row.tuesdayAMstatus=='4'" style="color:blue">
                                        △<br>
                                        {{row.tuesdayAM}}
                                    </div>
                                    <div v-else-if="row.tuesdayAMstatus=='3'" style="color:red">
                                        ○<br>
                                        {{row.tuesdayAM}}
                                    </div>
                                    <div v-else-if="row.tuesdayAMstatus=='0'" style="color:red" >
                                        ×<br>
                                        {{row.tuesdayAM}}
                                    </div>
                                    <div v-else>
                                        <span v-if="row.name=='接送人数'">
                                            {{row.tuesdayAM}}
                                        </span>
                                        <span v-else>
                                            <span v-if="row.tuesdayAM">
                                                <el-image style="width: 100px; height: 40px"
                                                :src="row.tuesdayAM"></el-image>
                                            </span>
                                        </span>
                                    </div>
                                </template>
                                </el-table-column>
                                <el-table-column :label="item.label" :prop="item.prop" :key="item.name" v-if="item.prop=='tuesdayPM'">
                               <template slot-scope="{row}">
                                    <div v-if="row.tuesdayPMstatus=='1'||row.tuesdayPMstatus=='2'" style="color:green">
                                        √<br>
                                        {{row.tuesdayPM}}<br>
                                        <el-image v-if="row.tuesdayPMsignUrl" style="width: 100px; height: 20px"
                                        :src="row.tuesdayPMsignUrl"></el-image>
                                    </div>
                                    <div v-else-if="row.tuesdayPMstatus=='4'" style="color:blue">
                                        △<br>
                                        {{row.tuesdayPM}}
                                    </div>
                                    <div v-else-if="row.tuesdayPMstatus=='3'" style="color:red">
                                        ○<br>
                                        {{row.tuesdayPM}}
                                    </div>
                                    <div v-else-if="row.tuesdayPMstatus=='0'" style="color:red" >
                                        ×<br>
                                        {{row.tuesdayPM}}
                                    </div>
                                    <div v-else>
                                        <span v-if="row.name=='接送人数'">
                                            {{row.tuesdayPM}}
                                        </span>
                                        <span v-else>
                                            <span v-if="row.tuesdayPM">
                                                <el-image style="width: 100px; height: 40px"
                                                :src="row.tuesdayPM"></el-image>
                                            </span>
                                        </span>
                                    </div>
                                </template>
                                </el-table-column>
                                <el-table-column :label="item.label" :prop="item.prop" :key="item.name" v-if="item.prop=='wednesdayAM'">
                                    <template slot-scope="{row}">
                                        <div v-if="row.wednesdayAMstatus=='1'||row.wednesdayAMstatus=='2'" style="color:green">
                                            √<br>
                                            {{row.wednesdayAM}}<br>
                                            <el-image v-if="row.wednesdayAMsignUrl" style="width: 100px; height: 20px"
                                            :src="row.wednesdayAMsignUrl"></el-image>
                                        </div>
                                        <div v-else-if="row.wednesdayAMstatus=='4'" style="color:blue">
                                            △<br>
                                            {{row.wednesdayAM}}
                                        </div>
                                        <div v-else-if="row.wednesdayAMstatus=='3'" style="color:red">
                                            ○<br>
                                            {{row.wednesdayAM}}
                                        </div>
                                        <div v-else-if="row.wednesdayAMstatus=='0'" style="color:red" >
                                            ×<br>
                                            {{row.wednesdayAM}}
                                        </div>
                                        <div v-else>
                                            <span v-if="row.name=='接送人数'">
                                                {{row.wednesdayAM}}
                                            </span>
                                            <span v-else>
                                                <span v-if="row.wednesdayAM">
                                                    <el-image style="width: 100px; height: 40px"
                                                    :src="row.wednesdayAM"></el-image>
                                                </span>
                                            </span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column :label="item.label" :prop="item.prop" :key="item.name" v-if="item.prop=='wednesdayPM'">
                                    <template slot-scope="{row}">
                                        <div v-if="row.wednesdayPMstatus=='1'||row.wednesdayPMstatus=='2'" style="color:green">
                                            √<br>
                                            {{row.wednesdayPM}}<br>
                                            <el-image v-if="row.wednesdayPMsignUrl" style="width: 100px; height: 20px"
                                            :src="row.wednesdayPMsignUrl"></el-image>
                                        </div>
                                        <div v-else-if="row.wednesdayPMstatus=='4'" style="color:blue">
                                            △<br>
                                            {{row.wednesdayPM}}
                                        </div>
                                        <div v-else-if="row.wednesdayPMstatus=='3'" style="color:red">
                                            ○<br>
                                            {{row.wednesdayPM}}
                                        </div>
                                        <div v-else-if="row.wednesdayPMstatus=='0'" style="color:red" >
                                            ×<br>
                                            {{row.wednesdayPM}}
                                        </div>
                                        <div v-else>
                                            <span v-if="row.name=='接送人数'">
                                                {{row.wednesdayPM}}
                                            </span>
                                            <span v-else>
                                                <span v-if="row.wednesdayPM">
                                                    <el-image style="width: 100px; height: 40px"
                                                    :src="row.wednesdayPM"></el-image>
                                                </span>
                                            </span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column :label="item.label" :prop="item.prop" :key="item.name" v-if="item.prop=='thursdayAM'">
                                     <template slot-scope="{row}">
                                        <div v-if="row.thursdayAMstatus=='1'||row.thursdayAMstatus=='2'" style="color:green">
                                            √<br>
                                            {{row.thursdayAM}}<br>
                                            <el-image v-if="row.thursdayAMsignUrl" style="width: 100px; height: 20px"
                                            :src="row.thursdayAMsignUrl"></el-image>
                                        </div>
                                        <div v-else-if="row.thursdayAMstatus=='4'" style="color:blue">
                                            △<br>
                                            {{row.thursdayAM}}
                                        </div>
                                        <div v-else-if="row.thursdayAMstatus=='3'" style="color:red">
                                            ○<br>
                                            {{row.thursdayAM}}
                                        </div>
                                        <div v-else-if="row.thursdayAMstatus=='0'" style="color:red" >
                                            ×<br>
                                            {{row.thursdayAM}}
                                        </div>
                                        <div v-else>
                                            <span v-if="row.name=='接送人数'">
                                                {{row.thursdayAM}}
                                            </span>
                                            <span v-else>
                                                <span v-if="row.thursdayAM">
                                                    <el-image style="width: 100px; height: 40px"
                                                    :src="row.thursdayAM"></el-image>
                                                </span>
                                            </span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column :label="item.label" :prop="item.prop" :key="item.name" v-if="item.prop=='thursdayPM'">
                                    <template slot-scope="{row}">
                                        <div v-if="row.thursdayPMstatus=='1'||row.thursdayPMstatus=='2'" style="color:green">
                                            √<br>
                                            {{row.thursdayPM}}<br>
                                            <el-image v-if="row.thursdayPMsignUrl" style="width: 100px; height: 20px"
                                            :src="row.thursdayPMsignUrl"></el-image>
                                        </div>
                                        <div v-else-if="row.thursdayPMstatus=='4'" style="color:blue">
                                            △<br>
                                            {{row.thursdayPM}}
                                        </div>
                                        <div v-else-if="row.thursdayPMstatus=='3'" style="color:red">
                                            ○<br>
                                            {{row.thursdayPM}}
                                        </div>
                                        <div v-else-if="row.thursdayPMstatus=='0'" style="color:red" >
                                            ×<br>
                                            {{row.thursdayPM}}
                                        </div>
                                        <div v-else>
                                            <span v-if="row.name=='接送人数'">
                                                {{row.thursdayPM}}
                                            </span>
                                            <span v-else>
                                                <span v-if="row.thursdayPM">
                                                    <el-image style="width: 100px; height: 40px"
                                                    :src="row.thursdayPM"></el-image>
                                                </span>
                                            </span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column :label="item.label" :prop="item.prop" :key="item.name" v-if="item.prop=='fridayAM'">
                                    <template slot-scope="{row}">
                                        <div v-if="row.fridayAMstatus=='1'||row.fridayAMstatus=='2'" style="color:green">
                                            √<br>
                                            {{row.fridayAM}}<br>
                                            <el-image v-if="row.fridayAMsignUrl" style="width: 100px; height: 20px"
                                            :src="row.fridayAMsignUrl"></el-image>
                                        </div>
                                        <div v-else-if="row.fridayAMstatus=='4'" style="color:blue">
                                            △<br>
                                            {{row.fridayAM}}
                                        </div>
                                        <div v-else-if="row.fridayAMstatus=='3'" style="color:red">
                                            ○<br>
                                            {{row.fridayAM}}
                                        </div>
                                        <div v-else-if="row.fridayAMstatus=='0'" style="color:red" >
                                            ×<br>
                                            {{row.fridayAM}}
                                        </div>
                                        <div v-else>
                                            <span v-if="row.name=='接送人数'">
                                                {{row.fridayAM}}
                                            </span>
                                            <span v-else>
                                                <span v-if="row.fridayAM">
                                                    <el-image style="width: 100px; height: 40px"
                                                    :src="row.fridayAM"></el-image>
                                                </span>
                                            </span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column :label="item.label" :prop="item.prop" :key="item.name" v-if="item.prop=='fridayPM'">
                                    <template slot-scope="{row}">
                                        <div v-if="row.fridayPMstatus=='1'||row.fridayPMstatus=='2'" style="color:green">
                                            √<br>
                                            {{row.fridayPM}}<br>
                                            <el-image v-if="row.fridayPMsignUrl" style="width: 100px; height: 20px"
                                            :src="row.fridayPMsignUrl"></el-image>
                                        </div>
                                        <div v-else-if="row.fridayPMstatus=='4'" style="color:blue">
                                            △<br>
                                            {{row.fridayPM}}
                                        </div>
                                        <div v-else-if="row.fridayPMstatus=='3'" style="color:red">
                                            ○<br>
                                            {{row.fridayPM}}
                                        </div>
                                        <div v-else-if="row.fridayPMstatus=='0'" style="color:red" >
                                            ×<br>
                                            {{row.fridayPM}}
                                        </div>
                                        <div v-else>
                                            <span v-if="row.name=='接送人数'">
                                                {{row.fridayPM}}
                                            </span>
                                            <span v-else>
                                                <span v-if="row.fridayPM">
                                                    <el-image style="width: 100px; height: 40px"
                                                    :src="row.fridayPM"></el-image>
                                                </span>
                                            </span>
                                        </div>
                                    </template>
                                </el-table-column>
                            </template>
                        </el-table-column>
                    </template>
                    <el-table-column prop="contactPhone" label="联系电话" ></el-table-column>

                
                    </el-table>
                    <div style="text-align:center;">
                        <el-pagination
                            :current-page.sync="pageIndex"
                            :total="totalElements"
                            :page-sizes="pageSizeList"
                            @current-change="changePage"
                            @size-change="pageSizeChange"
                            layout="total, sizes, prev, pager, next, jumper"
                        ></el-pagination>
                    </div> 
                </el-tab-pane>

                <!-- 

                    -->
            </el-tabs>
        </template>

    </div>
</template>
<script>
import schoolBusHandoverApi from "@/api/bus/schoolBusHandover";
import detentionWarningApi from '@/api/bus/detentionWarning'
import routeApi from "@/api/bus/route";
import scheduleApi from "@/api/bus/schedule";
export default {
    name: "busSchoolBusHandoverList",
    data(){
        return{
            ruleValidate: {
                schoolId: [{ required: true, message: "不能为空", trigger: "blur" }],
                routeId: [{ required: true, message: "不能为空", trigger: "blur" }],
                scheduleId: [{ required: true, message: "不能为空", trigger: "blur" }],
                beginDate: [{ required: true, message: "不能为空", trigger: "blur" }],
            },
            queryModel:{
                schoolId:"",
                routeId:"",
                scheduleId:"",
                beginDate:""
            },
            downloadLoading: false,
            schoolName:"",
            licensePlateNumber:"",
            driverName:"",
            lifeguardName:"",
            routeName:"",
            scheduleName:"",
            tableConfig:[],
            schoolList:[],
            scheduleList:[],
            activeName: '',
            routeList:"",
            showModal: false,
            loading: false,
            tableData: [],
            pageIndex: 1,
            pageSize: 10,
            totalElements: 0,
            pageSizeList: [10, 20, 30]
        }
    },
    methods: {
        changePage(pageIndex) {
            var self = this;
            this.loading = true;
            var formData = new FormData();

            var date = this.queryModel.beginDate;


            formData.append("pageIndex", this.pageIndex);
            formData.append("pageSize", this.pageSize);
            formData.append("schoolId", this.queryModel.schoolId);
            formData.append("routeId", this.queryModel.routeId);

            //var routeId = this.queryModel.routeId;

            // var scheduleId = "";

            // if(this.queryModel.scheduleId==""||this.queryModel.scheduleId==null){
            //     schoolBusHandoverApi.getDefaultSchedule(routeId).then(response=>{
            //         var jsonData = response.data;

            //         var data = jsonData.data

            //         scheduleId = data;
            //     })
            // }
            // else{
            //     scheduleId = this.queryModel.scheduleId;
            // }


            formData.append("scheduleId", this.queryModel.scheduleId);
            formData.append("date", date);


            var formData1 = new FormData();
            formData1.append("schoolId", this.queryModel.schoolId);
            formData1.append("routeId", this.queryModel.routeId);
            formData1.append("scheduleId", this.queryModel.scheduleId);
            formData1.append("date", date);

            this.schoolName="";
            this.licensePlateNumber="";
            this.driverName="";
            this.lifeguardName="";
            this.routeName="";
            this.scheduleName="";

            schoolBusHandoverApi.headerData(formData1).then(response=>{
                this.loading = false;
                var jsonData = response.data;

                if(jsonData.result){
                    var data = jsonData.data

                    this.schoolName = data.schoolName;
                    this.licensePlateNumber = data.licensePlateNumber;
                    this.driverName = data.driverName;
                    this.lifeguardName = data.lifeguardName;
                    this.routeName = data.routeName;
                    this.scheduleName = data.scheduleName;
                }
            })

            schoolBusHandoverApi.getSelectWeek(date).then(response=>{
                this.loading = false;
                var jsonData = response.data;
                this.tableConfig = jsonData.data;
            })


            schoolBusHandoverApi.pageList(formData).then(response => {
                this.loading = false;
                var jsonData = response.data;

                console.log(jsonData);

                this.tableData = jsonData.data.data;
                this.totalElements = jsonData.data.recordsTotal;

                this.pageIndex = pageIndex;
            });
        },
        handleReset(name) {
        this.$refs[name].resetFields();
        },
        pageSizeChange(pageSize) {
        this.pageSize = pageSize;
        },

        handleClick(tab, event) {
            var tabScheduleId = event.target.getAttribute('id');
            var index = tabScheduleId.indexOf("-");
            this.queryModel.scheduleId = tabScheduleId.substring(index+1);
            this.changePage(1)
        },
        handleQuery() {
            var self = this;

            this.$refs["queryForm"].validate(valid => {
                if (valid) {
                    //self.changePage(1);
                    var routeId = this.queryModel.routeId;

                    scheduleApi.routeScheduleList(routeId).then(response => {
                        var jsonData = response.data;
                        this.scheduleList = jsonData.data;
                    });
                }
            });
        },
        downloadXls() {
            //导出
            var self = this;
            self.downloadLoading = true;
            self.loading = true;
            self.loadingText = "数据导出中，请稍后...";
            var date = this.queryModel.beginDate;

            var formData = new FormData();

            formData.append("schoolId", this.queryModel.schoolId);
            formData.append("routeId", this.queryModel.routeId);
            formData.append("scheduleId", this.queryModel.scheduleId);
            formData.append("date", date);

            var timestamp = new Date().getTime();
            formData.append("timestamp", timestamp);

            formData.append("pageSize", 2000);

            schoolBusHandoverApi.exportXls(formData).then(function(response) {
                var jsonData = response.data;
                self.downloadLoading = false;
                self.loading = false;
                if (jsonData.result) {
                //下载有错误信息提示的报表
                self.$message({
                    showClose: true,
                    dangerouslyUseHTMLString: true,
                    message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                    duration: 60000,
                    type: "success"
                });
                } else {
                self.$message.error(jsonData.message);
                }
            });
        },

    },
    created() {
        var self = this;

        routeApi.routeList().then(response => {
            var jsonData = response.data;
            this.routeList = jsonData.data;
        });

        detentionWarningApi.schoolList().then(response => {
            var jsonData = response.data;
            this.schoolList = jsonData.data;
        });
    },
}
</script>
<style>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}
.schoolBusHandover-list {
  text-align: left;
}
.el-table td,.el-table th{
  text-align: center;
}
.headerDateDiv{
    margin: 10px;
}
.headerDateSpan{
    margin-left: 50px;
}
</style>